.menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 40px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    z-index: 10;
}
  
.menu-btn__burger {
    width: 30px;
    height: 3px;
    background: #000;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}
  
.menu-btn__burger::before,
.menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #000;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}
  
.menu-btn__burger::before {
    transform: translateY(-8px);
}
  
.menu-btn__burger::after {
    transform: translateY(8px);
}
  
.menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}
  
.menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}
  
.menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
}

.navbar ul li {
    transition: all 0.6s;
}

.header {
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 12vh;
    background: var(--light-blue);
    border-bottom: solid 2px #000;
    z-index: 5000;
}

.navbar.active {
    height: 100vh;
    position: absolute;
    background-color:var(--light-blue);
    
}

.logo {
    display: flex;
    width: auto;
    gap: 12px;
    align-items: center;
}
.logo-name{
    font-family: 'Red Hat Display', sans-serif;
}

nav header{
    font-family:'Outfit', sans-serif;
}

nav ul {
    font-family:'Red Hat Display', sans-serif;
}


.logo-name a {
    color: #000;
    text-decoration: none;
    font-size: 1.4rem;
}

li {
    list-style: none;
}


