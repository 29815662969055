  .articles-page{
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction:column;
    gap:2em;
    min-height:100vh;
  }
  h1{
    font-size:2.7rem;
    font-family:'Red Hat Display', sans-serif;
    font-style:italic;
    font-weight:400;
    text-align:center;
  }


  .spinner{
    animation:spinner 4s ease 2s forwards infinite;
    color:var(--btn-blue)

  }

  @keyframes spinner{
    0%{
      transform:rotate(0deg)
    }
    10%{
      transform:rotate(36deg);
    }
    20%{
      transform:rotate(72deg);
    }
    30%{
      transform:rotate(108deg);
    }
    40%{
      transform:rotate(144deg);
    }
    50%{
      transform:rotate(180deg);
    }
    60%{
      transform:rotate(216deg);
    }
    70%{
      transform:rotate(252deg);
    }
    80%{
      transform:rotate(288deg);
    }90%{
      transform:rotate(324deg);
    }
    100%{
      transform:rotate(360deg);
    }

  }





/* 
  .articles-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center; 
  }

  .articles-header{
    font-size:28px;
    font-family:"Poppins", sans-serif;
    text-align:center;
    margin:1em;
    font-weight:500;
  }
  
  .card p {
    line-height: 1.2;
    letter-spacing:1px;
    font-size:14px;
    margin:20px 20px 20px 15px;
    font-family:'Red Hat Display', sans-serif;
    text-align:left;

  } 
  .card a {
    color:#5c5cc5;
    cursor:pointer;
  }

  .card-column {
    width: 50%;
    padding: 4%;
    box-sizing: border-box;
  }
  
  .column-1 {
    padding-top: 100px;
  }
  
  .card {
    width: 92%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #fff;
    margin-bottom: 60px;
    padding-bottom:1em;
    text-align:center;
    background-color:rgba(255, 255, 255, 0.733);
    outline: 1px solid #fff;
    outline-offset: -6px;
  }


  .card h1 {
    position: relative;
    color:rgb(67, 83, 116);
    font-size:28px;
    font-weight:400;
    padding: 190px 0px 0 10px;
    width: 90%;
    text-align:left;
  }
  
  .card > img {
    width: 90%;
    position: absolute;
    top: -6%;
    left: -6%;
  }
  
  .card-color-0, .card-color-1, .card-color-2, .card-color-3{
    background-color: rgb(154, 176, 247);
  } */
  


