.sliderContainerStyles{
	width:300px;
	height:200px;
	margin: 2em 0;
}

.leftArrowStyles{
	position:absolute;
	top:45%;
	transform: translate(0, - 50%);
	left: -35px;
	font-size:30px;
	color:black;
	cursor:pointer;
	z-index: 1;
}

.rightArrowStyles{
	position:absolute;
	top:45%;
	transform: translate(0, - 50%);
	right: -35px;
	font-size:30px;
	color:black;
	cursor:pointer;
	z-index: 1;
}

.slideStyles{
	width:100%;
	height:100%;
	border-radius:10px;
	background-position:center;
	background-size:cover;
	transition:3s all;

}

.sliderStyles{
	height:100%;
	position: relative;
}