.office-page{
    display:flex;
    flex-direction:column;
    justify-content: center;
    place-items:center;
    padding-top:9em;
    text-align:center;
    font-family:'Red Hat Display', sans-serif;
    gap:1em;
}

.medical-equipment-section{
    display:grid;
    gap:2em;
    place-content:center;
    padding:2%;
    text-align: center;
    font-family:'Poppins', sans-serif;
}

.med-equip-header{
    margin-top:2em;
    font-size:1.5rem;
    padding:5px 1px;
    border:1px solid #4583bd;
    box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.25);

}

.machine-sample{
    display:grid;
    grid-template-rows:auto auto;
    grid-template-columns:auto auto auto;
    gap:.7em;
    padding:2%;
    align-items:center;
}

.machine-sample header{
    grid-column:1 /span 3;
    font-size:1.3rem;
    color:#4583bd;
}
.machine-sample img{
    width:210px;
    height:auto;
    overflow:hidden;    
    object-fit:scale-down;
    grid-row:2;
    grid-column: 1;
}



.machine-sample p{
    line-height: 1.3;
    font-size:12px;
    grid-row:2;
    grid-column:3;
    text-align:left;
}



.text-container{
    padding:.8em;
    max-width:320px;
    gap:1em;
    display:flex;
    flex-direction: column;
    line-height:1.3;
    
}

.text-container header{
    font-size:1.5rem;
}

.text-container p{
    font-size:15px;
}

.google-maps-img{
    display:flex;
    flex-direction:column;
    gap:1em;
    margin-bottom:4em;

}
.google-maps-img img{
    width:90%;
    border:1px solid black;
}

.google-maps-img h1{
    font-family:'Arimo',sans-serif;
    font-size:22px;

}


