@media screen and (min-width:860px){
    .bio-img{
        display:unset;
    }

    .logo-name a{
        font-size:1.4rem;
    }

    .navbar ul li a{
        font-size:1.2em;
    }

    .bio-page{
        display:flex;
        justify-content: center;
        align-items:center;
        gap:3em;
    }

    .bio-info{
        gap:4em;
    }


    .contact-img{
        display:unset;
        
    }

    .contact-elements h1{
        text-align:left; 
        font-size:1.9rem;
    }

    .footer-header{
        font-size:1.5rem;
    }

    .input1 input, .input2 input, .input3 input, .input4 textarea{
    width:450px;
    height:44px;
    }

    .input4 textarea{
        height:160px;
    }



    .footer-box .name-job h1{
        font-size:2rem;
    }

    .footer-box .name-job p{
        font-size:1.4rem;
    }

    .footer-box .contacts p{
        font-size:1.3rem;
    }

    .rights{
        font-size:17px;
    }

    .google-maps-img img{
        width:60%;
    }
    .google-maps-img h1{
        font-size:.7rem;
        margin-bottom:1.3rem;
    }


    .office-page{
        gap:7rem;
        padding-top:5.5em;
    }

    .office-page .text-background{
        /* background:url('../images/background-img.png'); */
        background:url('../images/background-image.jpg');
        opacity:0.9;
        height:80vh;
        background-size: cover;
        display:grid;
        place-content: center;
        width:99vw;
    }

    .office-page .text-background .text-container{
        display:flex;
        flex-direction:column;
        gap:2em;
        background: rgba(227, 230, 228, 0.6);
        max-width:700px;
        padding:4em;
    }

    .office-page .text-background .text-container p{
        font-size:16px;
}

.office-page .text-background .text-container header{
    font-size:2rem;
}

.medical-equipment-section{
    justify-items:center;
    grid-template-columns:repeat(3, 1fr)
}

.med-equip-header{
    font-size:2rem;
    padding:3px 10%;
}

.machine-sample{
    justify-content:center;
    grid-column:1 / span 2;
    grid-template-rows: auto  auto auto;
    justify-items: center;
}

.machine-sample header{
    grid-column:2 / span 3;
    grid-row:1;
    font-size:1.5rem;
}

.machine-sample img{
    grid-column: 1;

}

.machine-sample p{
    grid-column: 2;
    grid-row:2;
    font-size:30px;
    max-width:500px;
}

.article-title{
    text-align:center;
    margin-bottom:1em;
}



}
@media all and (max-width: 767px) {
    .navbar {
        position: fixed;
        top: 12vh;
        width: 100%;
        height: 0vh;
        flex-direction: column;
        background: var(--light-blue);
        left: 0%;
        align-items: center;
        transition: all 0.8s;
        display: flex;
        overflow: hidden;
    }
    
    
.navbar header {
    margin: 10px 0;
    font-size: 36px;
}

.navbar.active {
    height: 100vh;
    position: absolute;
    background-color:var(--light-blue);
    
}

.navbar ul li a {
    font-size: 32px;
    text-decoration: none;
    color: #000;
}
.navbar ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    gap: 20px;
}
}

@media all and (min-width: 768px) {
    .menu-btn {
        display: none;
    }
    .navbar, .navbar ul {
        display: flex;
        gap: 15px;
        position: relative;
    }
    
    
    .navbar ul li {
        display: flex;
        align-items: center;
    }
    .navbar ul li a {
        color: #000;
        text-decoration: none;
        font-family:'Red Hat Display', sans-serif;
        font-weight:bold;
        letter-spacing: 1px;
        font-size: .8rem;
    }
    .logo-name a {
        font-size: 20px;
    }
    .dropdown {
        float: left;
    }


    .navbar .title {
        display: none;
    }

    .office-page{
        padding-top:4.7em;
    }
   
    .office-page .text-background{
        height:90vh;
        padding:0;
    }

    .office-page .text-background .text-container p{
        font-size:18px;
}

.office-page .text-background .text-container header{
    font-size:1.7rem;
}
.google-maps-img h1{
    font-size:1.8rem;
}

.google-maps-img img{
    width:60%;
}


.sliderContainerStyles{
	width:500px;
	height:300px;
	margin: 1.5em 0;
}


.services-container{
    grid-template-columns:auto auto;
}

.services-container .service-sample{
    padding: 6% 2%;
}

.biography-page .header-and-img{
    display:flex;
    flex-direction: row;
}

.biography-page .header-and-img header{
    visibility:hidden;
    position: relative;
}

.biography-page .header-and-img header::after{
    top:0;
    position: absolute;
    visibility:visible;
    right:0;
    right:0;
    bottom:0;
    content:'Δημήτριος Τριγκούδης Χειρουργός Οφθαλμίατρος Απόφοιτος ΑΠΘ';
    display:flex;
    font-size:2rem;
    align-items:center;
    text-align:right;
    font-family:'Poppins', sans-serif;
    text-shadow: 1px 1px  gray;
    font-style:italic;
    color:black
}

.biography-page .bio-text-container p{
    max-width:600px;
}

.biography-page .header-and-img img{
     width:50%;
}

    .box-name, .box-doctor, .box-city{
        width:350px;
        height:90px;
    }
    .box-name h1, .box-doctor h1, .box-city h1{
        font-size:2rem;
    }

    .med-equip-header{
        grid-column:1 / span 3;
    }

    .machine-sample{
        justify-content:center;
        grid-column:1 / span 2;
        grid-template-rows: auto  auto auto;
        justify-items: center;
    }
    
    .machine-sample header{
        grid-row:1;
        font-size:1.5rem;
    }
    
    .machine-sample img{
        grid-column: 1;
        /* width:70%; */
    }
    
    .machine-sample p{
        grid-column: 2;
        grid-row:2;
        font-size:19px;
        min-width:200px;
    }

    .articles-container{
        display:grid;
        grid-template-columns: repeat(2, auto);
        justify-items:center;
    }
    
    .card{
        width:160%;
    }
        
    
}

@media screen and (max-width: 600px) {
    .card-column {
      width: 90%;
    }
    
    .column-1 {
      padding-top: 0px;
    }
  }

@media all and (min-width:1020px){
    .home-page{
        padding-top:2vh;
    }
    .bg-homepage{
        justify-content: start;
        background-size:cover;
        margin-top:10vh;
        height:90%;
    }

    .navbar ul li a {
        font-size: 1.2rem;
    }
    .logo-name a {
        font-size: 25px;
    }


    .box-name, .box-doctor, .box-city{
        clip-path: polygon( 0% 0%, calc(100% - 3em) 0%, 100% 50%, calc(100% - 3em) 100%, 0% 100%);
        transform:translateX(-15em);
        height:100px;
        width:800px;
        animation: fadeInRight 1s ease forwards;
        justify-content:flex-start;
    }
    .box-doctor{
        animation-delay:.5s;
        width:710px;
    }

    .box-city{
        animation-delay:1s;
        width:600px;
    }
    .box-name h1, .box-doctor h1, .box-city h1{
        font-size:3.5rem;
        text-align:left;
    }

    .card{
        width:190%;
    }

}