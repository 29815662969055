

.footer {
    padding: 2rem 0 0 0;
}

.footer .footer-header,
.footer-header span {
    font-size: 1.4rem;
}

.footer .footer-header {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 2rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
}

.footer .footer-header span {
    color: var(--font-blue);
}

.footer-box {
    background-color: #B2D8ED;
    padding: 2rem 1rem 3.5rem 1rem;
    height: 70%;
    text-align: center;
    
}

.footer-box .name-job {
    line-height: 1.5;
}

.footer-box .name-job h1 {
    font-weight: 400;
    font-size: 1.4rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer-box .name-job p {
    font-weight: 300;
    font-size: 1rem;
}

.footer-box .contacts p {
    font-size: 1.1rem;
    letter-spacing: 1px;
}

.footer-box .contacts span {
    color: var(--font-blue);
    margin: 0 .1rem;
}


.footer-box .contacts{
    display:grid;
    grid-template-rows:repeat(2, 1fr);
}

.phone-mobile-container{
    display:flex;
    justify-content: center;
}

.icon-text-container{
    display:flex;
    gap:1rem;
    margin:.7rem;
}

.icon-text-container p, span{
    font-family:'Arimo', sans-serif;
}

#phone-container{
    display:grid;
}
#mobile-container{
    display:grid;
}
#email-container{
    display:grid;
}


.footer-box h2 {
    font-weight: 400;
    font-size: 1.5rem;
    font-family:'Red Hat Display', sans-serif;
    margin-top: 2rem;
}

.rights {
    position: absolute;
    bottom: 1%;
    left: 3%;
    font-size: .6rem;
    font-family:'Poppins', sans-serif;
}

