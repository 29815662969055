@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@600&display=swap');

:root {
    --typewriterSpeed: 2s;
    --typewriterCharacters: 22;
    --grey-bg: #ddd;
    --light-blue: #D3E7F3;
    --font-blue: #5098DB;
    --btn-blue: #31a8fd;
}

* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

HTML{
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

section {
    position: relative;
}

    ::-webkit-scrollbar {
    width: 10px;
    
}

    ::-webkit-scrollbar-thumb {
    background: #82c9f0;
    border-radius: 20px;

}

.container {
    width: 100%;
    min-height: 100vh;
}

