.article{
    padding:6rem 1em 1em 1em;
    display:flex;
    flex-direction:column;
    justify-content: center;
    /* align-items:center; */
    gap:25px;
}

.article-title{
    font-family:'Arimo', sans-serif;
    font-weight:500;
    font-size:28px;
}

.article-body{
    border-left: 2px solid var(--btn-blue);
    padding-left:5px;
}

.article img{
    width:50%;
    float:right;
    padding:5px 20px;
}

.article p{
    text-align:justify;
    line-height:.6cm;
    font-family: Georgia, 'Times New Roman', Times, serif;

}

.date{
    font-size:13px;
    letter-spacing:1px;
    font-family:'Outfit', sans-serif;
}