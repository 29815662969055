.biography-page{
    padding: 14vh 4% 4% 4%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.biography-page .header-and-img{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:1em;
}

.biography-page header{
    font-size:1.4rem;
    text-align:center;    
    font-family:'Red Hat Display', sans-serif;
    color:black;
    padding-top:3%;
    letter-spacing: 1px;
    text-transform:capitalize;

}

.biography-page img{
    width:60%;
    border:1px solid black;
}

.bio-text-container{
    display:flex;
    flex-direction:column;
    gap:.3em;
}

.bio-text-container h1{
    font-size:1.2rem;
    font-family:'Arimo', sans-serif;
}

.bio-text-container li {
    list-style-type:none;
    gap:.5em;
    display:flex;
    font-size:17px;
    margin-bottom:1.5em;
    font-family:'Red Hat Display', sans-serif;
    font-weight:500;
    max-width:90%;
    position: relative;
}

.list-design{
    height:100%;
    position: absolute;
    left:-7px;
    display:block;
    width:2px;
    background-color:var(--font-blue);
}
