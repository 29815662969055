@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

.home-page {
    height: 100vh;
    padding:12vh 0 0 0;
}

.bg-homepage{
    height:80vh;
    display:grid;
    grid-template-rows:auto auto auto;
    background-size:cover;
    background-position:center;
    justify-content: center;
    padding:3% 0;
    
}

.box-name, .box-doctor, .box-city{
    padding:5px 8px;
    width:350px;
    height:100px;
    background-color:rgb(48, 44, 44, 0.7);
    display:flex;
    align-items:center;
    opacity:0;
    transform:translateY(40em);
    animation:fadeInUp 2s ease forwards;
    justify-content: center;
    text-align:center;
}

.box-doctor{
    animation-delay:.5s;
}

.box-city{
    animation-delay:1s;
}


.box-name h1, .box-doctor h1, .box-city h1{
    color:white;
    letter-spacing:1px;
    font-size:30px;
    font-weight:500;
    line-height:1.5;
}

@keyframes fadeInUp{
    to{
        transform:translateY(0);
        opacity:.8;
    }
}

@keyframes fadeInRight{
    to{
        transform:translateX(0);
        opacity:.8;
    }
}

.bio-page{
    display:grid;
    grid-template-rows: .9fr  auto;
    gap:10%;
    padding:6% 3%;
    place-content: center;
}

.bio-info{
    display:grid;
    place-content:center;
    gap:10%;
}

.bio-info h1{
    font-size:26px;
    display:flex;
    justify-content: center;
    font-family:'Poppins', sans-serif;
}

.bio-info p{
    max-width:400px;
    text-align:center;
    line-height:1.4;
    font-family:'Outfit', sans-serif;
}

.bio-link{
    text-decoration: underline;
    color:var(--btn-blue);

}

.bio-btn{
    color:#fff;
    padding:3% 4%;
    background-color: var(--btn-blue);
    justify-content: center;
    align-items: center;
    display:flex;
    border-radius:4px;
    transition:all .5s ease;
    width:50%;
    justify-self:center;
}

.bio-btn:hover{
    transform:scale(1.1);
}


.contact-page{
    display:flex;
    gap:2%;
    justify-content: center;
    padding:6% 2%;
    position: relative;
    font-family:'Outfit', sans-serif;
}

.contact-elements{
    display:flex;
    flex-direction: column;
    gap:6%;
}
.contact-elements h1{
    text-align:center;
    font-family: 'Red Hat Display', sans-serif;
    max-width:600px;
    margin-bottom:5%;
    font-size:1.4rem;
    line-height:1.2;
}

.input1, .input2, .input3, .input4{
    display:flex;
    flex-direction: column;
    gap:4px;
    margin-bottom:11px;
    transition:all .25s ease;
}

.username-error{
    font-size:12px;
    color:red;
    display:none;
    transition:all .25s ease;

}

.email-error{
    font-size:12px;
    color:red;
    display:none;
    transition:all .25s ease;
}

.phoneNumber-error{
    font-size:12px;
    color:red;
    display:none;
    transition:all .25s ease;
}

.message-error{
    font-size:12px;
    color:red;
    display:none;
    transition:all .25s ease;}

.input1 input, .input2 input, .input3 input, .input4 textarea{
    width:350px;
    height:34px;
    padding:1%;
    margin-bottom:2%;
    font-size:14px;
    border:1px solid black;
    border-radius:4px;
    outline:none;
    background-color:var(--light-blue);
    color:black;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.input4 textarea{
    height: 120px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.contact-elements label{
    font-size:15px;

}

.contact-elements input[type='submit']{
    background-color:var(--btn-blue);
    color:white;
    font-size:1rem;
    padding:2% 4%;
    border:none;
    outline:none;
    border-radius:8px;
    transition:all .5s ease;
}

.contact-elements input[type='submit']:hover{
    transform:scale(1.1);
}



.bio-img{
    display:none;
    width:100%;
}

.contact-img{
    display:none;
    width:40%;
    position: relative;
    top:50px;
    right:50px;
}

